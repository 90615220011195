@import '../../style/themes/index';
@import '../../style/mixins/index';

@switch-prefix-cls: ~'@{ant-prefix}-switch';
@switch-duration: 0.2s;

@switch-pin-size: @switch-height - 4px;
@switch-sm-pin-size: @switch-sm-height - 4px;

.@{switch-prefix-cls} {
  .reset-component();

  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: @switch-min-width;
  height: @switch-height;
  line-height: @switch-height;
  vertical-align: middle;
  background-image: linear-gradient(to right, @disabled-color, @disabled-color),
    linear-gradient(to right, @white, @white);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all @switch-duration;
  user-select: none;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px fade(@disabled-color, 10%);
  }

  &-checked:focus {
    box-shadow: 0 0 0 2px @primary-1;
  }

  &:focus:hover {
    box-shadow: none;
  }

  &-checked {
    background: @switch-color;
  }

  &-loading,
  &-disabled {
    cursor: not-allowed;
    opacity: @switch-disabled-opacity;

    * {
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  // ========================= Inner ==========================
  &-inner {
    display: block;
    margin: 0 @switch-inner-margin-min 0 @switch-inner-margin-max;
    color: @text-color-inverse;
    font-size: @font-size-sm;
    transition: margin @switch-duration;
  }

  &-checked &-inner {
    margin: 0 @switch-inner-margin-max 0 @switch-inner-margin-min;
  }

  // ========================= Handle =========================
  &-handle {
    position: absolute;
    top: @switch-padding;
    left: @switch-padding;
    width: @switch-pin-size;
    height: @switch-pin-size;
    transition: all @switch-duration ease-in-out;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: @switch-bg;
      border-radius: (@switch-pin-size / 2);
      box-shadow: 0 2px 4px 0 @switch-shadow-color;
      transition: all @switch-duration ease-in-out;
      content: '';
    }
  }

  &-checked &-handle {
    left: calc(100% - @switch-pin-size - @switch-padding);
  }

  &:not(&-disabled):active {
    .@{switch-prefix-cls}-handle::before {
      right: -30%;
      left: 0;
    }

    &.@{switch-prefix-cls}-checked {
      .@{switch-prefix-cls}-handle::before {
        right: 0;
        left: -30%;
      }
    }
  }

  // ======================== Loading =========================
  &-loading-icon.@{iconfont-css-prefix} {
    position: relative;
    top: ((@switch-pin-size - @font-size-base) / 2);
    color: rgba(0, 0, 0, 0.65);
    vertical-align: top;
  }

  &-checked &-loading-icon {
    color: @switch-color;
  }

  // ========================== Size ==========================
  &-small {
    min-width: @switch-sm-min-width;
    height: @switch-sm-height;
    line-height: @switch-sm-height;

    .@{switch-prefix-cls}-inner {
      margin: 0 @switch-sm-inner-margin-min 0 @switch-sm-inner-margin-max;
      font-size: @font-size-sm;
    }

    .@{switch-prefix-cls}-handle {
      width: @switch-sm-pin-size;
      height: @switch-sm-pin-size;
    }

    .@{switch-prefix-cls}-loading-icon {
      top: ((@switch-sm-pin-size - 9px) / 2);
      font-size: 9px;
    }

    &.@{switch-prefix-cls}-checked {
      .@{switch-prefix-cls}-inner {
        margin: 0 @switch-sm-inner-margin-max 0 @switch-sm-inner-margin-min;
      }

      .@{switch-prefix-cls}-handle {
        left: calc(100% - @switch-sm-pin-size - @switch-padding);
      }
    }
  }
}

@import './rtl';

@root-entry-name: variable;